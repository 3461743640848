import React, { Component } from "react"
import styled from "styled-components"
import className from "classnames"
import { Link } from "gatsby"
import _ from "lodash"
import ScrollBar from "react-perfect-scrollbar"
import * as videojs from "video.js"
import ReactPlayer from "react-player"
import "react-perfect-scrollbar/dist/css/styles.css"

import constant from "../../constant"
import Layout, { UserInfoContext } from "../../components/layout"
import SEO from "../../components/seo"
import withI18next from "../../components/withI18next"
import { getLink } from "../../utils"
import { getKeyInfo } from "../../http"

const PHeader = styled.header`
  margin: 0 auto;
  margin-top: -80px;
  max-width: 1920px;
  height: 396px;
  padding-top: 146px;
  background: linear-gradient(to bottom, #708bff, #8ba1ff);
  canvas {
    position: absolute;
    top: 0;
    bottom: 0;
  }
  h1 {
    font-size: 34px;
  }
`

const PContent = styled.div`
  margin: 0 auto;
  max-width: 1920px;
  background: #f6f9ff;
  height: 420px;
  @media (max-width: 750px) {
    height: auto;
  }
  .price-list {
    max-width: 1458px;
    margin: 0 auto;
    transform: translateY(-165px);
    .price-card {
      position: relative;
      width: 278px;
      height: 509px;
      border-radius: 8px;
      border: none;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
      margin: 0 auto;
      @media (max-width: 750px) {
        margin-top: 20px;
      }
      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 0 0 2px transparent, 0px 9px 10px 0px rgba(0, 0, 0, 0.1);
      }
      &:nth-child(1) h2 {
        background: linear-gradient(
          90deg,
          rgba(80, 163, 248, 1) 0%,
          rgba(59, 124, 241, 1) 100%
        );
      }
      &:nth-child(2) h2 {
        background: linear-gradient(
          90deg,
          rgba(102, 193, 251, 1) 0%,
          rgba(96, 173, 251, 1) 100%
        );
      }
      &:nth-child(3) h2 {
        background: linear-gradient(
          90deg,
          rgba(136, 90, 247, 1) 0%,
          rgba(104, 72, 234, 1) 100%
        );
      }
      &:nth-child(4) h2 {
        background: linear-gradient(
          90deg,
          rgba(94, 200, 178, 1) 0%,
          rgba(77, 187, 157, 1) 100%
        );
      }
      &:nth-child(5) h2 {
        background: linear-gradient(
          90deg,
          rgba(238, 206, 75, 1) 0%,
          rgba(245, 193, 81, 1) 100%
        );
      }
      p {
        width: 194px;
        margin: 0 auto;
      }
      h2 {
        height: 90px;
        line-height: 90px;
        font-size: 16px;
        margin-bottom: 0;
        border-radius: 8px 8px 0px 0px;
      }
      .card-subtitle {
        margin-top: 20px;
        margin-bottom: 6px;
        font-size: 16px;
        color: ${constant.subTitle};
      }
      .desc {
        color: #8992bf;
        font-size: 14px;
        margin-bottom: 20px;
      }
      .price {
        font-size: 34px;
        line-height: 75px;
        color: #ffa502;
        height: 75px;
        border-bottom: 1px solid #eee;
      }
      .login-btn {
        position: absolute;
        left: 50%;
        bottom: 33px;
        width: 183px;
        height: 43px;
        border-radius: 24px;
        transform: translateX(-50%);
      }
    }
  }
`

const LeagueContent = styled.div`
  /* padding-top: 64px; */
  /* padding-bottom: 72px; */
  margin: 0 auto;
  margin-top: 64px;
  max-width: 1180px;
  /* width: 1180px; */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  .league-type {
    height: 56px;
    background: ${constant.productBg};
    padding: 0 30px;
    .list-group {
      height: 100%;
      li {
        width: 73px;
        line-height: 36px;
        color: ${constant.desc};
        cursor: pointer;
        border-radius: 2px;
        &:hover {
          color: ${constant.active};
        }
        &.active {
          color: white;
          background: ${constant.active};
        }
      }
    }
  }
  .league-content {
    padding: 0 30px;
    height: 440px;
    .figure-container {
      padding: 30px 0;
      border-bottom: 1px solid #eee;
      &:nth-child(2n) {
        flex-direction: row-reverse;
      }
      &.wrap {
        flex-direction: row;
        flex-wrap: wrap;
        .text {
          order: -1;
        }
        .pic,
        .text {
          flex: 0 0 100%;
        }
      }
      .text {
        h4 {
          font-size: 24px;
        }
        p {
          font-size: 14px;
        }
        word-break: break-all;
        flex-shrink: 1;
        flex-basis: 400px;
      }
      .pic {
        flex-basis: content;
      }
    }
  }
  .video-content {
    padding: 10px 0 0 !important;
  }
`

const Project = styled.div`
  max-width: 1496px;
  /* min-width: 1180px; */
  margin: 0 auto;
  h2 {
    font-size: 34px;
    color: ${constant.subTitle};
    margin-bottom: 20px;
  }
  .project-footer {
    position: relative;
    height: 106px;
    margin-top: 26px;
    background: #f6f9ff;
    line-height: 106px;
    @media (max-width: 750px) {
      height: 150px;
    }
    .cost {
      font-size: 24px;
      color: #ffa502;
    }
    .buy-btn {
      vertical-align: 3px;
      border-radius: 2px;
      color: white;
      background: #ffa502;
    }
    .buy-tip {
      position: absolute;
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%);
      color: #aaa;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .project-container {
    max-width: 1200px;
    margin: auto;
    padding: 0 10px;
    .project-item {
      margin-bottom: 20px;
    }
    .game-type {
      margin-right: 40px;
      .logo {
        width: 75px;
        height: 75px;
        border: 1px solid ${constant.logoBorder};
        border-radius: 50%;
        margin-right: 15px;
        img {
          display: block;
          width: 50px;
          height: 50px;
          margin: 12.5px auto;
          object-fit: contain;
        }
      }
      .game-figure {
        width: 80px;
        height: 75px;
        .game-name {
          font-size: 20px;
          margin-bottom: 9px;
        }
        .game-link {
          display: inline-block;
          width: 80px;
          font-size: 14px;
          color: ${constant.active};
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .item-select {
      flex-grow: 1;
      height: 50px;
      border-radius: 25px;
      color: #61689a;
      background: #e8ecff;
      padding: 4px;
      @media (max-width: 750px) {
        margin-top: 20px;
      }
      &.fade_product {
        background: white;
        pointer-events: none;
        border-radius: unset;
      }
      .s-item {
        flex: 1 1 233px;
        border-radius: 25px;
        line-height: 42px;
        cursor: pointer;
        &.active {
          color: white;
          background: ${constant.active};
          @media (max-width: 750px) {
            background: ${constant.active} !important;
          }
        }
      }
    }
  }
`

const Problem = styled.div`
  margin: 0 auto;
  max-width: 1092px;
  h2 {
    margin-bottom: 50px;
  }
  .problem-container {
    padding: 0 20px;
  }
  .problem-item {
    flex-basis: 456px;
    margin-bottom: 38px;
    h4 {
      font-size: 24px;
      color: ${constant.active};
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      color: ${constant.subTitle};
    }
  }
`
const game = ["dota2", "lol", "csgo", "aov"]
const LEVEL_NUM = 5 // 提供数据套餐的等级

function selectItem(ps, type, id) {
  const product = [...ps]
  const p = countPId(type, id)
  product[type] = p
  return product
}

function countPId(type, i) {
  const base = Number(type) * LEVEL_NUM
  return base + Number(i) + 1
}

function countPrice(product, buyStatus) {
  const prices = [0, 10000, 20000, 50000, 100000]
  return product.reduce((acc, cur, idx) => {
    const valid = buyStatus[idx]
    if (!valid) return acc
    const p = prices[(cur - 1) % LEVEL_NUM]
    return acc + p
  }, 0)
}

const initProduct = [1, 6, 11, 16]
const levelData = {
  dota2: [true, true, true, true, true],
  lol: [true, true, true, true, false],
  csgo: [true, true, true, true, true],
  aov: [true, true, false, false, false],
}

const Price = class Price extends Component {
  state = {
    cur: 0,
    product: initProduct,
    buy: "",
    curProduct: initProduct,
    buyStatus: [true, true, true, true],
    playing: false,
  }
  setCur(cur) {
    this.setState({ cur })
  }
  setProduct(product) {
    this.setState({ product })
  }
  handlePlay = () => {
    this.setState({ playing: true })
  }
  handlePause = () => {
    this.setState({ playing: false })
  }
  componentDidMount() {
    const buyStatus = [...this.state.buyStatus]
    const product = [...this.state.product]
    getKeyInfo()
      .then(res => {
        if (res.status === 0) {
          res.data.member.forEach(p => {
            const { member_product_type_id, level } = p
            const game = Number(member_product_type_id) - 1
            if (level > 1) {
              buyStatus[game] = false
            }
            const base = game * LEVEL_NUM // 该游戏产品的基础id
            const pI = base + level
            product[game] = pI
          })
          this.setProduct(product)
          this.setState({ curProduct: product })
          this.setState({ buyStatus })
        }
      })
      .catch(err => console.log(err))
  }
  render() {
    const { t } = this.props
    const { cur, product, curProduct, buyStatus, playing } = this.state
    const status = ["intro", "before", "mid", "after"]
    const sumPrice = countPrice(product, buyStatus)
    const introData = t(`price.league.${status[cur]}`, { returnObjects: true })
    return (
      <Layout type="price">
        <SEO title="价格" />
        <PHeader className="price-header" id="product-header">
          <h1 className="text-center text-white">{t("price.title")}</h1>
        </PHeader>
        <PContent>
          <ul className="price-list list-group list-group-horizontal justify-content-between flex-wrap">
            {t("price.card_list", { returnObjects: true }).map((card, cIdx) => (
              <li className="card price-card text-center" key={card.name}>
                <h2 className="text-white">
                  <img className="mr-2" src={constant.priceIcon[cIdx]} alt="" />
                  {card.name}
                </h2>
                <p className="price">￥{card.cost}</p>
                {card.info.map(d => (
                  <React.Fragment key={d.title}>
                    <p className="card-subtitle">{d.title}</p>
                    <p className="desc">{d.desc}</p>
                  </React.Fragment>
                ))}
                {/* {cIdx !== 0 &&
                    <UserInfoContext.Consumer>
                      {(user) => user.notLogin ? (
                        <Link to={getLink("/login")} className="login-btn web-button btn text-white">{t("price.login")}</Link>
                      ) : (
                          <a className="login-btn web-button btn text-white" href="#buy-project">{t("price.buy_project")}</a>
                        )}
                    </UserInfoContext.Consumer>
                  } */}
              </li>
            ))}
          </ul>
        </PContent>
        <LeagueContent>
          <header className="league-type">
            <ul className="list-group list-group-horizontal text-center align-items-center">
              {t("price.league.select", { returnObjects: true }).map(
                (slt, idx) => (
                  <li
                    key={slt}
                    className={className({
                      "active button-shadow": cur === idx,
                    })}
                    onClick={() => this.setCur(idx)}
                  >
                    {slt}
                  </li>
                )
              )}
            </ul>
          </header>
          <ScrollBar>
            {_.isArray(introData) ? (
              <div className="league-content">
                {introData.map((figure, idx) => (
                  <div
                    className={className(
                      "figure-container d-flex justify-content-between",
                      { wrap: figure.wrap }
                    )}
                    key={idx}
                  >
                    <div className="pic">
                      <img src={constant[status[cur]][idx]} alt=" " />
                    </div>
                    <div className="text">
                      <h4>{figure.title}</h4>
                      <p>{figure.desc}</p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="league-content video-content">
                <ReactPlayer
                  style={{ cursor: "pointer" }}
                  height="430px"
                  width={"100%"}
                  controls
                  url={constant.owl_intro}
                  playing={playing}
                  onPlay={this.handlePlay}
                  onPause={this.handlePause}
                >
                  {/* <source src={constant.owl_intro} type='video/mp4' /> */}
                  您的浏览器不支持 video 标签。
                </ReactPlayer>
              </div>
            )}
          </ScrollBar>
        </LeagueContent>
        <Project className="mt-5" id="buy-project">
          <h2 className="text-center">{t("price.title")}</h2>
          <div className="project-container">
            {constant.Logo.map((l, idx) => (
              <div
                className="project-item align-items-center d-flex flex-wrap"
                key={game[idx]}
              >
                <div className="game-type d-flex">
                  <div className="logo">
                    <img src={l} alt=" " />
                  </div>
                  <div className="game-figure">
                    <p className="game-name">
                      {t(`provideData.${idx}.title`).toLocaleUpperCase()}
                    </p>
                    <Link
                      to={getLink(`/product/#${idx}`)}
                      className="game-link"
                    >
                      {t("price.project.more_info")}
                    </Link>
                  </div>
                </div>
                <div
                  className={className("item-select text-center d-flex", {
                    fade_product: !buyStatus[idx],
                  })}
                >
                  {t("price.project.type_list", { returnObjects: true }).map(
                    (it, n) => (
                      <div
                        className={className("s-item", {
                          "active web-button":
                            product[idx] === countPId(idx, n),
                          fade_product: curProduct[idx] !== initProduct[idx],
                        })}
                        style={{
                          color: !levelData[game[idx]][n] && "#3B416D40",
                        }}
                        key={it}
                        onClick={() => {
                          levelData[game[idx]][n] &&
                            this.setProduct(selectItem(product, idx, n))
                        }}
                      >
                        {it}
                      </div>
                    )
                  )}
                </div>
              </div>
            ))}
          </div>
          {/* <div className="project-footer text-center">
            {t("price.project.pay", { returnObjects: true })[0]}
            <span className="cost">￥{sumPrice}</span>
            <UserInfoContext.Consumer>
              {user => {
                if (user.notLogin === true) {
                  return (
                    <Link
                      className="ml-2 button-shadow buy-btn btn"
                      to={getLink("/login")}
                    >
                      {t("price.login")}
                    </Link>
                  )
                }
                return (
                  <React.Fragment>
                    <Link
                      className={className("buy-btn button-shadow btn ml-2", {
                        gray: sumPrice === 0,
                      })}
                      to={getLink("/price/order")}
                      state={{
                        type: "buy",
                        id: product.filter(
                          (p, idx) =>
                            buyStatus[idx] &&
                            p !== 1 &&
                            p !== 6 &&
                            p !== 11 &&
                            p !== 16
                        ),
                      }}
                    >
                      {t("price.buy")}
                    </Link>
                    <span
                      className="buy-tip"
                      dangerouslySetInnerHTML={{
                        __html: t("price.buy_tip", {
                          href: getLink("/usercenter/#2"),
                        }),
                      }}
                    />
                  </React.Fragment>
                )
              }}
            </UserInfoContext.Consumer>
          </div> */}
        </Project>
        <Problem className="mt-5">
          <h2 className="text-center">{t("price.problem.title")}</h2>
          <div className="problem-container d-flex justify-content-between flex-wrap">
            {t("price.problem.info", { returnObjects: true }).map(
              (info, idx) => (
                <div className="problem-item" key={info.title}>
                  <h4>{info.title}</h4>
                  <p>{info.desc}</p>
                </div>
              )
            )}
          </div>
        </Problem>
      </Layout>
    )
  }
}

export default withI18next({ ns: "common" })(Price)
